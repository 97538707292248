.settingScroll {
  overflow-y: scroll;

  /* height: 80vh; */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.settingScroll::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.settingScroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
