::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(197, 188, 188);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(226, 224, 224);
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

/* .accordion {
  max-width: 600px;
  margin: 2rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  padding: 23px;
  font-weight: normal !important;
}

.accordion-title,
.accordion-content {
  font-size: small;
  font-style: normal;
  font-weight: normal;
  color: #242424;
}

.accordion-content {
  background-color: white;
  margin-left: 40px;
  padding-bottom: 20px;
}

.sign {
  cursor: pointer;
}

.click {
  color: #3374b9;
  cursor: pointer;
}

.name{
  background-color: gray;
 
}

.PrivateTabIndicator-colorSecondary-6 {
  background-color:#3374B9 !important;
}

.MuiTab-wrapper  {
  font-family: "Poppins" !important;
  text-transform: capitalize !important;
  font-size: medium !important;
  color: "rgba(255, 255, 255, 0.8"!important;
  letter-spacing: normal !important;
}

.MuiTab-wrapper:hover{
  color: #FFFFFF !important;
  opacity: 1 !important;
} */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.accordion {
  max-width: 650px;
  /* height: 548px; */
  position: absolute;
  margin-top: 30px;
  overflow: hidden;
}

.sign {
  cursor: pointer;
}

.click {
  color: #3374b9;
  cursor: pointer;
}

.name {
  background-color: gray;
}

.PrivateTabIndicator-colorSecondary-6 {
  background-color: transparent !important;
}

.MuiTab-wrapper {
  font-family: "Poppins" !important;
  text-transform: capitalize !important;
  font-size: medium !important;
  color: "rgba(255, 255, 255, 0.8" !important;
  letter-spacing: normal !important;
  font-weight: 200 !important;
}

.MuiTab-wrapper:hover {
  color: #ffffff !important;
  opacity: 1 !important;
}

#question {
  color: #3374b9;
}

.que-div {
  color: #242424;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Poppins" !important;
  text-transform: capitalize !important;
}
.ans-div {
  color: #242424;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Poppins" !important;
  margin: -13px 0px 0px 50px !important;
}

.MuiAccordion-root:before {
  background-color: transparent !important;
}

.desc {
  color: #242424;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Poppins" !important;
}

.MuiGrid-grid-md-5 {
  max-width: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
