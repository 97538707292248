ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a,
a:link,
a:visited,
a:hover,
a:active {
  color: #000000;
  text-decoration: none;
}

.menu {
  position: absolute;
  background-color: #fefefe;
  width: 238px;
  box-shadow: 0px 2px 6px 2px rgba(51, 116, 185, 0.15);
  border-radius: 5px;
  z-index: 100;
  margin-bottom: 100px;
}

.menu-option {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  flex-direction: row;
}

.menu-option-download:hover {
  background-color: #ebf1f8;
}
.menu-option-delete:hover {
  background-color: #f9eeed;
}

.menu-option-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0015em;
}

.download {
  color: #242424;
}

.delete {
  color: #d0766b;
  cursor: pointer;
}
