.button-container {
    /* margin-bottom: 0.5rem; */
  }
  .button-container2 {
    /* margin-bottom: 0.5rem; */
    display: flex;
  }
  
  .fade-enter .btn {
    opacity: 0;
    transform: translateY(100%);
  }
  .fade-enter-active .btn {
    opacity: 1;
    transform: translateY(0%);
  }
  .fade-exit .btn {
    opacity: 1;
    transform: translateY(0%);
  }
  .fade-exit-active .btn {
    opacity: 0;
    transform: translateY(-100%);
  }
  .fade-enter-active .btn,
  .fade-exit-active .btn {
    transition: opacity 500ms, transform 500ms;
  }

  .showScroll::-webkit-scrollbar {
    /* display: none; */
    width: 0px;
    height: 10px;
  }