.stepperScroll {
	padding-top: 20px;
	overflow-x: scroll;
	/* height: 100%; */
}

.stepperScroll::-webkit-scrollbar {
	display: none;
}

.stepperScroll {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.mainContainer {
	background-color: #f0f6fd;
	overflow-x: hidden;
	overflow-y: hidden;
}

@media only screen and (max-width: 960px) {
	.mainContainer {
		padding-left: 30px;
	}
}
