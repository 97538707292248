.esgReportListingMainDiv{
    background-color: #F5F6F8;
    
}
.esgReportListingMainGrid{
    display: flex;
     align-items: center;
      padding-left: 80px;
}
.esgReportListingLink{
    text-decoration: none;
}
.esgReportListingLinkIcon{
    margin-right: 20px;
}
.esgReportListingLinkArrow{
    box-sizing: initial;
}
.esgReportListingTypography{
    margin-left: 100px;
    padding: 50px 0px;
}