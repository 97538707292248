.donutchart-innertext-value{
    font-weight: 700;
    font-size: 20px;
    /* filter: blur(4px); */
}

.arcs-paths{
    border: solid red;
    color: red;
}

.main{
    text-align: center;
}

