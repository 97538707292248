.generalSettingsContainer {
  background-color: #f3f4f4;
}
.generalSettingsItem {
  position: relative;
  
}
.generalSettingsImage1 {
  position: absolute;
  bottom: 10px;
  left: 20px;
  
}
.generalSettingsImage2 {
  position: absolute;
  top: 20px;
  right: 20px;
}
.generalSettingsCard {
  background-color: #ffffff;
  border-top-left-radius: 25;
}
